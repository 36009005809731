@font-face {
    font-family: 'printania';
    src: local('printania'), url('ui/fonts/Printania Sans Web Bold.woff');
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
}

.list-style-none {
    list-style: none;
}
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.header-content {
    padding: 1.6em 0;
}
@media (max-width: 768px) {
    .header-content {
        padding: 1em 0 !important;
    }
}

.header-content a {
    text-decoration: none;
}

@media (min-width: 768px) {
    .header-content .logo {
        top: 5px;
    }
}

.header-content .logo {
    text-align: center;
    top: -5px;
    position: relative;
}

@media (min-width: 768px) {
    .header-content .logo--title {
        max-width: 100%;
    }
}

@media (min-width: 768px) and (max-width: 1366.98px) {
    .header-content .logo--title {
        width: 250px;
    }
}

.header-content .logo--title {
    font-family: printania;
    font-size: 1.25rem;
    text-transform: uppercase;
    font-weight: 700;
    color: #121212;
    line-height: 1.35rem;
}

@media (min-width: 768px) {
    .header-content .logo--title-pcom {
        font-size: 1.6rem;
    }
}

.header-content .logo--title-pcom {
    font-size: 1.4rem;
    font-weight: 700;
    letter-spacing: -1px;
    transform: scaleY(0.99);
}

.mb-1,
.my-1 {
    margin-bottom: 0.25rem !important;
}

.mt-0,
.my-0 {
    margin-top: 0 !important;
}

.header-content .logo--subtitle {
    margin-top: -7px;
}
@media (min-width: 768px) {
    .header-content .logo--subtitle {
        margin-top: -7px;
    }
}
@media (min-width: 1367px) {
    .header-content .logo--subtitle {
        margin-top: -4px;
    }
}
.header-content .logo--subtitle {
    font-family: printania;
    font-weight: 700;
    font-style: normal;
    width: 100%;
    display: block;
    text-transform: none !important;
    font-size: 0.63rem;
    margin-bottom: 0;
}

.header-bottom .mega-menu__content--card__link,
.pb-2,
.py-2,
.select-full ul li:last-child {
    padding-bottom: 0.5rem !important;
}

.header-content .logo--title-sticky {
    display: none;
}

@media (min-width: 768px) {
    .header-content .logo--title-pcom {
        font-size: 1.6rem;
    }
}

.header-content .logo--title-pcom {
    font-size: 1.4rem;
    font-weight: 700;
    letter-spacing: -1px;
    transform: scaleY(0.99);
}

.header-edito .logo--subtitle {
    margin-top: -7px;
}

.header-content .logo--subtitle {
    font-family: printania;
    font-weight: 700;
    font-style: normal;
    width: 100%;
    display: block;
    text-transform: none !important;
    font-size: 0.63rem;
    margin-bottom: 0;
    /*margin-top: -12px;*/
}

.color--black {
    color: #121212;
}

@media (min-width: 768px) {
    .pb-md-0,
    .py-md-0 {
        padding-bottom: 0 !important;
    }
}

.encart-container {
    position: relative;
}

.text-center {
    text-align: center !important;
}

.flex-column {
    flex-direction: column !important;
}

@media screen and (min-width: 577px) {
    .encart__title .encart-item__title {
        font-size: 3.15vw;
        line-height: 3.15vw;
    }
    .encart__title .encart-item__subtitle {
        font-size: 2.15vw;
        line-height: 3.15vw;
    }
}

.encart__title .encart-item__title {
    font-weight: 800;
    text-transform: uppercase;
    color: white;
}

a {
    font-family: printania, Arial, sans-serif;
}
