.App {
    text-align: center;
    overflow: hidden;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.react-calendar {
    border: none;
}
.react-calendar__tile--now {
    background-color: lightgrey !important;
}
.react-calendar__tile--active {
    background-color: #06fe00 !important;
    border-radius: 32px;
    color: black;
}
.react-calendar__navigation__label__labelText {
    font-weight: bold;
    font-size: 11px;
    text-transform: uppercase;
}

input[type='checkbox'] {
    position: relative;
    cursor: pointer;
}
input[type='checkbox']:before {
    content: '';
    display: block;
    position: absolute;
    width: 20px;
    height: 20px;
    bottom: -2px;
    right: -2px;
    border: 2px solid black;
    border-radius: 3px;
    background-color: white;
}

input[type='checkbox']:checked:after {
    content: '';
    display: block;
    width: 5px;
    height: 10px;
    border: solid #06fe00;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    top: 1px;
    left: 4px;
}
.mobile-menu {
    z-index: 1080;
    background-color: white;
    border-top: 2px solid #ccc;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
}

.mobile-menu-item {
    padding: 0.3rem;
    height: 64px;
}

.mobile-calendar-menu {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 64px;
    border-bottom: 2px solid #ccc;
}

.mobile-calendar-menu-content {
    display: flex;
    justify-content: center;
    margin-bottom: 64px;
}

.offcanvas {
    bottom: 64px !important;
    border-radius: 32px 32px 0 0;
    height: auto !important;
}

.ui-loader-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
}
.ui-loader-content > div {
    margin: 0.5rem;
}

.footer {
    padding: 2em;
}

.ui-pagination .col-1 {
    width: 32px;
    font-family: printania, Arial, sans-serif;
}

.content-policy {
    text-align: left;
}
:focus {
    outline: 0 !important;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
}

.header-content .logo--title-pcom {
    font-size: 1.4rem;
    font-weight: 700;
    letter-spacing: -1px;
    transform: scaleY(0.99);
}
@media (min-width: 768px) {
    .header-content .logo--title-pcom {
        font-size: 1.6rem;
    }
}
@media (min-width: 1367px) {
    .header-content .logo--title-pcom {
        font-size: 34px;
        font-weight: 700;
    }
}

@media (min-width: 1367px) {
    .header-content .logo--subtitle {
        font-size: 0.875rem;
    }
}

@media (min-width: 768px) {
    .header-content .logo--title {
        max-width: 100%;
    }
}

@media (min-width: 1367px) {
    .header-content .logo--title {
        max-width: 100%;
    }
}

.textShadow_custom {
    text-shadow: 0 3px 9px rgb(0 0 0);
}

.react-calendar__month-view__days__day--weekend {
    color: black !important;
}
